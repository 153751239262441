import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import { Document as RichTextDocument } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import LinkBack from 'components/LinkBack'

import 'styles/marketplace/category-template.scss'

type CategoryPageProps = {
  pageContext: {
    slug: string
    title: string
    products: {
      slug: string
      title: string
      subtitle: string
      shortDescription: { raw: string }
      images: {
        file: {
          url: string
        }
      }[]
    }[]
    webview?: boolean
  }
}

export default function CategoryPage({
  pageContext: { slug, title, products, webview },
}: CategoryPageProps): ReactElement {
  return (
    <AuthProvider>
      <UserProvider>
        <Layout
          className={webview ? 'category-page--webview' : 'category-page'}
          webview={webview}
        >
          <Helmet>
            <title>Jevitty Marketplace | {title}</title>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>

          <LinkBack webview={webview} to="/marketplace/">
            &#8592; See all categories
          </LinkBack>

          <h1 className="category-page__title">{title}</h1>

          {products ? (
            <div
              className={classNames('row row-cols-1', {
                'row-cols-md-2 row-cols-lg-3': !webview,
              })}
            >
              {products.map(
                ({
                  slug: productSlug,
                  title: productTitle,
                  subtitle,
                  shortDescription,
                  images,
                }) => (
                  <div className="col" key={productSlug}>
                    <article className="category-page__product">
                      <Link
                        className="category-page__product__link"
                        to={`${
                          webview ? '/webview' : ''
                        }/marketplace/${slug}/${productSlug}/`}
                      >
                        {images && (
                          <div className="category-page__product__image-wrapper">
                            <img
                              className="category-page__product__image"
                              src={images[0].file.url}
                              alt={productTitle}
                            />
                          </div>
                        )}
                        <h3 className="category-page__product__title">
                          {productTitle}
                        </h3>
                      </Link>

                      {subtitle && (
                        <h4 className="category-page__product__subtitle">
                          By {subtitle}
                        </h4>
                      )}

                      {shortDescription &&
                        documentToReactComponents(
                          JSON.parse(shortDescription.raw) as RichTextDocument,
                        )}

                      <Link
                        className={classNames(
                          'category-page__product__read-more btn btn-secondary',
                          { 'btn-lg': webview },
                        )}
                        to={`${
                          webview ? '/webview' : ''
                        }/marketplace/${slug}/${productSlug}/`}
                      >
                        Read more
                      </Link>
                    </article>
                  </div>
                ),
              )}
            </div>
          ) : (
            <p>No products in this category yet.</p>
            // trigger change 01/02/2024
          )}
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
